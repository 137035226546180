<template>
    <div class="pl-store__help-list-wrap">
        <div class="pl-store__help-item" v-for="(item, index) in list" :key="item.id + '-' + index">
            <div class="pl-store__help-title">{{ item.name }}</div>
            <div class="pl-store__help-subtitle">
                <span v-for="obj in subData[item.id]" :key="obj.id" @click="goPage(obj.id)">{{ obj.name }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import help from '@/mixin/help.js'
export default {
    mixins: [help],
    methods: {
        goPage(id) {
            this.$router.push(`/help/acticle/${id}`)
        }
    }
}
</script>
<style lang="scss" scoped>
.pl-store__help-title {
    width: 18px;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid #e7e7e7;
    font-weight: 600;
}

.pl-store__help-subtitle {
    padding-top: 15px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    span {
        width: 30%;
        margin: 10px 0;
        cursor: pointer;
    }
}

.pl-store__help-item {
    width: 100%;
    background: #fff;
    // margin-bottom: 20px;
    padding: 15px 20px;
    box-sizing: border-box;
    border-radius: 5px;
}
</style>